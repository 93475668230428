import React from 'react'
import TRUSTLINETABLE from './TRUSTLINETABLE'
import Table from 'react-bootstrap/Table';

export default function TRUSTLINECLEAN({trustlines}) {

  return (
    <div>
        <Table striped bordered hover size="sm">
          <thead>
            <tr>
              <th>#</th>
              <th>Address</th>
              <th>Balance</th>
            </tr>
          </thead>
          <tbody>
        { 
            trustlines.map((trustline, index) => {
                return (<TRUSTLINETABLE trustline={trustline} index={index} key={'tt_' +index}/>)
            })
        }
        </tbody>
    </Table>
    </div>
  )
}
