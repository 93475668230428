import React from 'react'

export default function XRPGIVEN({giveaways}) {
    var xrpTotal = 0.00;
    function calcTotal(){
        giveaways.map((giveaway) => {
        xrpTotal += giveaway.amount
        return []
        })
    }
    calcTotal();
  return (
    <div>
        XRP Given Away: {xrpTotal}
    </div>
  )
}
