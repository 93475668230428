import React, { useState, useCallback, useEffect } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import TRUSTLINECLEAN from '../components/trustlines/TRUSTLINECLEAN';
import TRUSTLINENAV from '../components/trustlines/TRUSTLINENAV';
import { Alert, Button, Col, Form, FormControl, Row } from 'react-bootstrap';

const xrplDedAss = {
  "id": 2,
  "command": "account_lines",
  "ledger_index": "validated"
}
export default function TrustlineSelector() {

  const { sendJsonMessage, lastMessage, readyState } = useWebSocket('wss://xrplcluster.com/');
  const [XRPLAddress, setXRPLAddress] = useState(xrplDedAss)
  const [trustlines, setTrustlines] = useState([]);
  const [marker, setMarker] = useState(true);
  const [account, setAccount] = useState('');
  const [randomimzer, setRandomizer] = useState('');
  const [hasTrustline, setHasTrustline] = useState(false);

  useEffect(() => {
    if (lastMessage !== [] && lastMessage !== null) {
      setHasTrustline(false)
      const x = JSON.parse(lastMessage.data).result;
      setTrustlines(prev => [...prev, ...x.lines]);
     if('marker' in x){
      const y = { ...XRPLAddress, 'marker': x.marker}
      sendJsonMessage(y)
      setXRPLAddress(y)
      setMarker(true)
     }else{
      setMarker(false)
     }
    }
  }, [lastMessage]);

  const handleClickFindTrustlines = useCallback(() =>  sendJsonMessage(XRPLAddress), [trustlines]);

  function handleChangeAddress(event) {
    const x = event.target.value
    if('marker' in XRPLAddress){
      delete XRPLAddress.marker
    }
    setAccount(x)
    setXRPLAddress({ ...XRPLAddress, 'account': x})
  }
  function handleClickRandomizer() { setRandomizer(trustlines[Math.floor(Math.random() * trustlines.length)].account) }
  function handleClickSetAccount() { 
    setTrustlines([])
    setHasTrustline(true);
    setMarker(true);
  }
  function handleClickClearAccount() { 
    setTrustlines([])
    setAccount('')
    setHasTrustline(false)
    document.getElementById('xrpladdress').value = ''
  }
  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState];

  return (
    <div className='tl'>
      <TRUSTLINENAV wsstate={connectionStatus}/>
      <br />
      {trustlines.length !== 0 ? <Alert variant='success'>Loaded Trustlines: {trustlines.length}</Alert> : null }
      <Form>
        <FormControl type="text" id='xrpladdress' name="xrpladdress" onChange={handleChangeAddress} placeholder="XRPL Address" />
        <br />
        <Row xs={'auto'}>
          <Col><Button variant="primary" onClick={handleClickSetAccount} disabled={account.length < 25}>Set Trustline</Button></Col>
          <Col><Button variant="success" onClick={handleClickFindTrustlines} disabled={readyState !== ReadyState.OPEN || !marker || !hasTrustline} >Load Trustlines</Button></Col>
          <Col><Button variant="warning" onClick={handleClickRandomizer} disabled={trustlines.length === 0}>Randomizer</Button></Col>
          <Col><Button variant="secondary" onClick={handleClickClearAccount}>Clear Trustline</Button></Col>
        </Row>
      </Form>
      <br />
      {randomimzer && 
        <div>Random Account:{' '}<a href={'https://bithomp.com/explorer/' + randomimzer} target='_blank' rel="noopener noreferrer">{randomimzer}</a></div>
      }
      <br />
      {trustlines.length !== 0 ? <TRUSTLINECLEAN trustlines={trustlines} /> : null}
    </div>
  );
};