import React from 'react'
import { DataStore } from '@aws-amplify/datastore';
import { XRPGIVEAWAY } from '../models';
import { useState, useEffect } from 'react';
import XRPTABLE from '../components/xrpcards/XRPTABLE';
import NAVBAR from '../components/NAVBAR'

export default function Cake() {
  const [xrpGiveaways, setxrpGiveaways] = useState([]);
  useEffect(() => {
    const queryXRPGiveaway = async () => {
        try {
            const response = await DataStore.query(XRPGIVEAWAY);
            response.sort(function (a, b) {
              a = a.end.toString().split('-');
              b = b.end.toString().split('-');
              return a[0] - b[0] || a[1] - b[1] || a[2] - b[2];
          })
          setxrpGiveaways(response);
        } catch(err) {
            console.log(err);
        }
    }
    const subscriptionXRPGiveaway = DataStore.observe(XRPGIVEAWAY).subscribe((msg) => { queryXRPGiveaway(); })
    queryXRPGiveaway();
    return () => subscriptionXRPGiveaway.unsubscribe();
  }, []);

  return (
    <div className='cake'>
      { xrpGiveaways &&
          <div>
            <NAVBAR giveaways={xrpGiveaways}/>
            <br />
            <div className='card-deck'>
              <XRPTABLE giveaways={xrpGiveaways}/>
            </div>
          </div> 
      }
    </div>
  )
}
