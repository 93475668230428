import React from 'react'
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom';
import dedassLogo from '../images/dedass192.png';
import XRPGIVEN from './XRPGIVEN';

export default function NAVBAR({giveaways}) {
  return (
    <Navbar bg='white' sticky="top">
        <Container>
          <Navbar.Brand>
            <Link to="/">
                <img
                alt=""
                src={dedassLogo}
                width="30"
                height="30"
                className="d-inline-block align-top"
                />
            </Link>
          </Navbar.Brand>
          <Navbar.Text>
            <XRPGIVEN giveaways={giveaways}/>
          </Navbar.Text>
        </Container>
      </Navbar>
  )
}
