import React, { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import { Check } from 'react-bootstrap-icons'

const tableHead = ['Date']

export default function XRPTABLE({ giveaways }) {
    const [dimensions, setDimensions] = useState({ 
        height: window.innerHeight,
        width: window.innerWidth
      })
    const [mobile, setMobile] = useState(false)
      useEffect(() => {
        window.innerWidth <= 480 ? setMobile(true) : setMobile(false);
        function handleResize() {
          setDimensions({
            height: window.innerHeight,
            width: window.innerWidth
          })
    }
    window.addEventListener('resize', handleResize)
  }, [window.innerWidth])


  return (
    <Table striped bordered hover>
        <thead className='tdXRPTable'>
        <tr>
          <th>Date</th>
          <th>Prize</th>
          <th>Winner</th>
          <th>View</th>
        </tr>
      </thead>
      <tbody>
            {
                giveaways.map((giveaway, index) => {
                return (
                    <tr key={'xrpCell-' + index} className='tdXRPTable'>
                        <td>{ giveaway.end }</td>
                        <td>{ giveaway.amount +  ' ' + giveaway.token}</td>
                        <td>{ mobile ? giveaway.winner.slice(0,4) + '...' : giveaway.winner }</td>
                        <td><a href={'https://bithomp.com/explorer/' + giveaway.confirmation} target="_blank"><Check color="black"/></a></td>
                    </tr>
                    )
                    }).reverse()
            }
      </tbody>
    </Table>
  )
}
