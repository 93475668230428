import React from 'react'
export default function TRUSTLINETABLE({trustline, index}) {
    return (
        <tr key={'trustline_' + index}>
            <td>{ index }</td>
            <td>{ trustline.account }</td>
            <td>{ trustline.balance }</td>
        </tr>
      )
}
