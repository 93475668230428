import React from 'react'
import { Container, Navbar, Spinner } from 'react-bootstrap'
import dedassLogo from '../../images/dedass192.png';
import { Link } from 'react-router-dom';
export default function TRUSTLINENAV({wsstate}) {
  return (
    <Navbar>
      <Container>
      <Navbar.Brand>
            <Link to="/">
                <img
                alt=""
                src={dedassLogo}
                width="30"
                height="30"
                className="d-inline-block align-top"
                />
            </Link>
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          <Navbar.Text>
            Connection: <Spinner animation="border" variant={wsstate === 'Open'? 'success' : 'danger'} size='sm'/>
          </Navbar.Text>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}
