import React from 'react';
import { Twitter, Instagram, Tiktok, Twitch, Diagram3Fill, Youtube } from 'react-bootstrap-icons';
import xrplLogo from '../images/xrplLogo.png';
import dedassLogo from '../images/dedass192.png';

export default function Handles() {
  return (
    <div className='home'>
        <a href='https://twitter.com/dedasslol' target='_blank' rel="noopener noreferrer" ><Twitter color="black" size={96} className='app-icon'/></a>
        <a href='https://instagram.com/dedass.lol' target='_blank' rel="noopener noreferrer" ><Instagram color="black" size={96} className='app-icon'/></a>
        <a href='https://tiktok.com/@dedass.lol' target='_blank' rel="noopener noreferrer" ><Tiktok color="black" size={96} className='app-icon'/></a>
        <a href='https://twitch.tv/dedasslol' target='_blank' rel="noopener noreferrer" ><Twitch color="black" size={96} className='app-icon'/></a>
        <a href='https://youtube.com/@dedasslol' target='_blank' rel="noopener noreferrer" ><Youtube color="black" size={96} className='app-icon'/></a>
        <a href='https://bithomp.com/explorer/rHYvhab6JEFcoipghVYjUz5LwMCB7sZmVm' target='_blank' rel="noopener noreferrer"  className='app-icon'><img width="96" height="96" src={xrplLogo} alt='XRPL Logo' className='app-icon'/></a>
        <a href="/cake"><img width="96" height="96" src={dedassLogo} alt='XRPL Logo' className='app-icon'/></a>
        <a href="/trustlineselector"><Diagram3Fill color="black" size={96} className='app-icon'/></a>
  </div>
  )
}
