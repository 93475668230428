import React from "react";

import './App.css'
import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom";

import Home from "./pages/Home";
import Cake from "./pages/Cake";
import NotFound from "./pages/NotFound";
import TrustlineSelector from "./pages/TrustlineSelector";
import TwitterPicker from "./pages/TwitterPicker";

function App() {

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/cake" element={<Cake />} />
        <Route path="/trustlineselector" element={<TrustlineSelector />} />
        <Route path="/twitterpicker" element={<TwitterPicker />} />
        <Route  path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
