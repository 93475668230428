import { Link } from "react-router-dom"
import React from 'react'
import dedassLogo from '../images/dedass192.png';

const NotFound = () => {
    return ( 
        <div className="home">
           <Link to="/"><img width="96" height="96" src={dedassLogo} alt='XRPL Logo' className='app-icon'/></Link>
        </div>
     );
}
 
export default NotFound;